export const SUDOKU_SET_ACTIVE_FIELD = "SUDOKU_SET_ACTIVE_FIELD";
export const SUDOKU_SET_NOTE = "SUDOKU_SET_NOTE";
export const SUDOKU_SET_NUMBER = "SUDOKU_SET_NUMBER";
export const SUDOKU_FETCH = "SUDOKU_FETCH";
export const SUDOKU_FETCH_SUCCESS = "SUDOKU_FETCH_SUCCESS";
export const SUDOKU_CLEAR = "SUDOKU_CLEAR";
export const SUDOKU_RESET = "SUDOKU_RESET";
export const SUDOKU_FETCH_FROM_STORAGE = "SUDOKU_FETCH_FROM_STORAGE";
export const SUDOKU_SET_IS_ERROR = "SUDOKU_SET_IS_ERROR";

export const APP_SHOW_MENU = "APP_SHOW_MENU";
